import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import OverviewPlants from "../../Images/Farm_Photos/OverviewPlants.jpeg";
import PottedMums from "../../Images/Farm_Photos/PottedMums.jpeg";
import TreeLine from "../../Images/Farm_Photos/TreeLine.jpeg";

const Home = () => {
	return (
		<Box>
			<Box
				padding={2}
				backgroundColor={
					//light red
					"#FFC0CB"
				}
			>
				<Typography variant="h4" align="center">
					Closed for the 2024 season
				</Typography>
			</Box>
			<Box padding={2}>
				<Grid container spacing={2} sx={{ mt: 4 }}>
					<Grid item xs={12} md={6}>
						{/* Google Map */}
						{/* Replace the iframe src with your own Google Map embed code */}
						<iframe
							title="Farm Location"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2827.518708729717!2d-93.0633!3d44.8488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87f7c8e6e6e6e6e6%3A0x6d6d6d6d6d6d6d6d!2s9226%20Old%20Concord%20Blvd%2C%20Inver%20Grove%20Heights%2C%20MN%2055076!5e0!3m2!1sen!2sus!4v1635432100000!5m2!1sen!2sus"
							width="100%"
							height="400"
							style={{ border: 0 }}
							allowFullScreen=""
							loading="lazy"
						></iframe>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Contact Information */}
						<Typography variant="h6" sx={{ mb: 2 }}>
							Contact Us:
						</Typography>
						<Typography variant="body1" sx={{ mb: 2 }}>
							Phone: 651-239-4444
						</Typography>

						{/* List of Plants */}
						<Typography variant="h6" sx={{ mb: 2 }}>
							Plants:
						</Typography>
						<ul>
							<li>Blueberries</li>
							<li>Raspberries</li>
							<li>Hydrangeas</li>
							<li>Veggie Starts</li>
							<li>Arborvitae</li>
							<li>Hostas</li>
							<li>Perennials</li>
							<li>Annuals</li>
							<li>And more!</li>
							{/* Add more plants as needed */}
						</ul>

						{/* Gallery */}
						<Typography variant="h6" sx={{ mb: 2 }}>
							Farm Gallery:
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={6} md={4}>
								{/* Add your farm photos here */}
								<img
									src={OverviewPlants}
									alt="Overview of Plants"
									style={{ width: "100%" }}
								/>
							</Grid>
							<Grid item xs={6} md={4}>
								{/* Add your farm photos here */}
								<img
									src={PottedMums}
									alt="Potted Mums"
									style={{ width: "100%" }}
								/>
							</Grid>
							<Grid item xs={6} md={4}>
								{/* Add your farm photos here */}
								<img src={TreeLine} alt="Tree Line" style={{ width: "100%" }} />
							</Grid>
							{/* Add more farm photos as needed */}
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default Home;
